<template>
  <div id="wishlist-view" class="mt-2">
    <p v-if="imageshow">
      <span class="d-flex justify-content-center"
        ><img
          class="img-fluid text-center"
          src="@/assets/site/images/Group1.svg"
          alt=""
      /></span>
    </p>
    <div class="container-fluid" v-if="productShow">
      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <div class="row">
            <div
              class="col-md-3"
              v-for="(wishlistproduct, key) in wishlistproducts"
            >
              <div class="owl-item active wizard-box">
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="deleteWishlist(wishlistproduct, key)"
                          class="fa fa-trash"
                          v-b-popover.hover.top="'Delete'"
                        ></b-button>
                      </div>
                      <a
                        @click="ViewSingleProduct(wishlistproduct)"
                        class="product photo product-item-photo"
                        tabindex="-1"
                      >
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px;"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%;"
                          >
                            <img
                              class="wizard-image-photo lazyload"
                              :src="wishlistproduct.imageurl"
                              data-src=""
                              loading="lazy"
                              max-width="300"
                              max-height="300"
                              alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                          /></span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <a
                          href="#"
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click="ViewSingleProduct(wishlistproduct)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ wishlistproduct.productname }}
                        </a>
                      </h2>
                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper "
                            ><span class="price"
                              >{{ wishlistproduct.price["@currency"] }}
                              {{ wishlistproduct.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label">Rating:</span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%;">87%</span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <wishlist-product
        v-if="!productShow"
        v-on:back="back"
        v-bind:sproduct="sproduct"
      ></wishlist-product>
    </div>
  </div>
</template>
<script>
import WishlistProduct from "@/views/site/user/WishlistProduct";

export default {
  name: "FriendswishlistView",
  props: ["wishlistproducts", "updateProducts"],
  metaInfo: {
    title: "Wishlist",
    meta: [
      {
        name: "description",
        content:
          "Manage your wishlist effortlessly with our intuitive interface. Browse your favorite products, view details, and easily remove items. Elevate your shopping experience and keep track of what you love!",
      },
      {
        name: "keywords",
        content:
          "wishlist view, Vue.js template, manage wishlist, product details, remove from wishlist, quick view feature, product slider, e-commerce, online shopping, user-friendly interface, product reviews, wishlist management, favorite products, shopping experience, responsive design, product pricing.",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      imageshow: false,
      wishlist_status: null,
      ViewProduct: false,
      sproduct: null,
      productShow: true,
    };
  },
  components: {
    WishlistProduct,
  },
  watch: {
    wishlistproducts: function(newVal, oldVal) {
      this.changeOccationProduct();
    },
  },
  methods: {
    changeOccationProduct() {
      if (this.productShow == false) {
        this.productShow = true;
      }
    },
    ViewSingleProduct(product) {
      this.productShow = false;
      this.sproduct = product;
    },
    back() {
      this.productShow = true;
      this.sproduct = null;
    },
    deleteWishlist(wishlistproduct, index) {
      this.loader = true;
      var productId = null;
      if (wishlistproduct.productId) {
        productId = wishlistproduct.productId;
      } else {
        productId = wishlistproduct.mid + "-" + wishlistproduct.linkid;
      }
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Do you want to remove  " + wishlistproduct.productname + "!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            let method_type = "";
            let fetch_url = "";
            method_type = "post";
            fetch_url =
              process.env.VUE_APP_URL + "customer/userwishlist/deleteProduct";
            fetch(fetch_url, {
              method: method_type,
              headers: {
                "content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
              },
              body: JSON.stringify({
                user_id: user_id,
                product_id: productId,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.status === true) {
                  this.loader = false;
                  this.updateProducts(index);

                  this.$swal.fire("Good to Go", data.message, "success");
                } else {
                  this.loader = false;
                  this.$swal.fire("Error!", data.message, "error");
                }
              })
              .catch(function(err) {
                console.log("Fetch Error :-S", err);
              });
          }
        });
    },
  },
};
</script>
